import { React, useContext, useState } from "react";
import NoteContext from "../context/NoteContext";

const AddNotes = () => {
  const noteState = useContext(NoteContext);

  const { addNote } = noteState;
  let [note, setNote] = useState({
    title: "",
    description: "",
    tags: "default",
  });

  const onclick = (e) => {
    e.preventDefault();
    console.log(note.title, note.description, note.tags);
    addNote(note.title, note.description, note.tags);
  };

  const onchange = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div class=" col-md-6 col-sm-12">
        <div class="card-body shadow ">
        <form className="row g-3" style={{ justifyContent: "center" }}>
        <div className="col-md-6 col-sm-12 mx-1">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            placeholder="Title"
            onChange={onchange}
          />
        </div>
        <div className="col-md-6 col-sm-12 mx-1">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className="form-control"
            name="description"
            id="description"
            placeholder="Description"
            onChange={onchange}
            rows="5"
          ></textarea>
        </div>

        <div className="col-6">
          <button type="submit" className="btn btn-primary" onClick={onclick}>
            Submit
          </button>
        </div>
      </form>
        </div>
      </div>
      
    </>
  );
};

export default AddNotes;
