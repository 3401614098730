import {React,useState} from 'react'
import AlertContext from './AlertContext';









function AlertState(props) {
    // login alert
const [successValues, setsuccessValues] = useState({type:"",message:""});
    const login=(type,message)=>{
        setsuccessValues({type,message})
        setTimeout(() => {
            setsuccessValues({type:null,message:null});
        }, 3000);
    }



  return (
    <AlertContext.Provider  value={{login,successValues}}>
        {props.children}
    </AlertContext.Provider>
  )
}

export default AlertState