import {React,useContext,useEffect,useRef,useState} from 'react'
import NoteContext from '../context/NoteContext'
import NoteItem from './NoteItem'
import {useNavigate} from "react-router-dom"
const Notes = () => {
  const navigate = useNavigate();
  const noteState=useContext(NoteContext)
  const {getAllNotes,allNotes}=noteState;
  const ref = useRef(null);
  
  useEffect(() => {
    if(localStorage.getItem('Authorization')){
      getAllNotes();
    }
    else{
      navigate('/login');
    }
    
 
  },[]);
  const [note, setCurrentNote] = useState({eid:"",etitle:"",edescription:""})
const edit=(currentNote)=>{
  ref.current.click( );
  
  setCurrentNote({eid:currentNote._id,etitle:currentNote.title,edescription:currentNote.description})
  
   
}

const handleUpdate=async(id)=>{
  console.log(id)
  let updateNotes=await fetch(`https://my-note-book007-app.herokuapp.com/notes/update-notes/${id}`,{
    method:'PUT',
    headers:{
        'Authorization':localStorage.getItem('Authorization'),
        'Content-Type':"application/json",
        // 'Access-Control-Allow-Origin':"https://notebook.melroypinto.com",
        // 'Access-Control-Allow-Credentials':true
        
        
    },
    body:JSON.stringify({title:note.etitle,description:note.edescription})
   

    
    
})
closeModal();
getAllNotes();
console.log(updateNotes);
}
const onChange=(e)=>{
  setCurrentNote({...note,[e.target.name]:e.target.value})
}

function closeModal(){
  console.log('asdas')
  ref.current.click( );


}
  
  
  return (
    <>
    <button type="button" className="btn btn-primary " style={{display:"none"}} ref={ref} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
        Launch static backdrop modal
    </button>


<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">Edit Note</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <form className="row g-3" style={{justifyContent:"center",}}>
  
  
      <div className="col-12 mx-1">
        <label htmlFor="title" className="form-label">Title</label>
        <input type="text" className="form-control" id="title" name='etitle' value={note.etitle} placeholder="Title" onChange={onChange}/>
      </div>
      <div className="col-12 mx-1">
        <label htmlFor="description" className="form-label">Description</label>
        <input type="text" className="form-control" name='edescription' id="description" value={note.edescription} placeholder="Description" onChange={onChange} />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Close</button>
        <button type="button" className="btn btn-primary" onClick={()=>{handleUpdate(note.eid)}}>Update</button>
      </div>
  
    </form>
      </div>
      
    </div>
  </div>
</div>
        
        
<div className='row' style={{justifyContent:"center"}}>
{allNotes.map((note)=>{
  return  <NoteItem key={note._id} edit={edit} note={note}/> 
})}
</div>
    
   
    </>
   
    
  )
}

export default Notes