import {React,useContext} from 'react'
import AlertContext from '../context/AlertContext';
import AlertState from '../context/AlertState';



function Alert() {

    const alertState=useContext(AlertContext);
    const {successValues}=alertState;

  return (
   successValues.type!=='' && successValues.type!==null &&
    <div className="alert alert-success" role="alert">
    {successValues.type},{successValues.message}
</div>
  )
}

export default Alert