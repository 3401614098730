import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    
    const [loginValues, setloginValues] = useState({name:"",email:"",password:""})
   const navigate=useNavigate();
    
    const handleSignUp=async(e)=>{
       e.preventDefault();
       const {name,email,password}=loginValues;
      
       console.log("inside handle sign up")
           
             let response=await fetch('https://my-note-book007-app.herokuapp.com/auth/create-user',{
                method:'POST',
                headers:{
                    
                    'Content-Type':"application/json",
                    // 'Access-Control-Allow-Origin':"https://notebook.melroypinto.com",
                    // 'Access-Control-Allow-Credentials':true
                    
                    
                },
                body:JSON.stringify({name,email,password})
            })

          const json=await response.json();
          console.log(json);
          
            if(json.success){
              
               alert('succes')
               navigate('/login');
            }
            else if(!json.success){
              alert('unsucces')

            }
            
            
    }


    const onchange=(e)=>{
        
        setloginValues({...loginValues,[e.target.name]:e.target.value})
        
        
        }



  return (
    <>
    
    <div className='container'>
    <h2 className="mt-2">Sign-Up here</h2>
    <form className="mt-5" onSubmit={handleSignUp}>
    <div className="mb-3">
    <label htmlFor="exampleInputText" className="form-label">Name</label>
    <input type="text" className="form-control" id="exampleInputText" name='name'  onChange={onchange} aria-describedby="emailHelp"/>
    
  </div>
  <div className="mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
    <input type="email" className="form-control" id="exampleInputEmail1" name='email'  onChange={onchange} aria-describedby="emailHelp"/>
    
  </div>
  <div className="mb-3">
    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
    <input type="password" className="form-control" name='password' onChange={onchange}  id="exampleInputPassword1"/>
  </div>
  <div className="mb-3">
  <label htmlFor="exampleInputPasswordConfirmation" className="form-label">confirm Password</label>
  <input type="password" className="form-control"  id="exampleInputPasswordConfirmation"/>
</div>
  
  <button type="submit" className="btn btn-primary" >Submit</button>
</form >
    </div>
    
</>
  )
}

export default Signup