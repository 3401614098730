import React from 'react'
import { useContext } from 'react';
import NoteContext from '../context/NoteContext';

const NoteItem = (props) => {
    const context=useContext(NoteContext)
    const{deleteNote}=context;
    
    const {note,edit}=props;
  return (
    <div className="card col-md-3 mx-3 my-3 shadow  bg-white " >
  <div className="card-body">
    <h5 className="card-title">{note.title}</h5>
    
    <p className="card-text">{note.description}</p>
    <i className="fa-solid fa-trash mx-1" style={{cursor:"pointer"}} onClick={()=>{deleteNote(note._id)}}></i>
    <i className="fa-solid fa-pencil mx-1" style={{cursor:"pointer"}} onClick={()=>{edit(note)}}></i>


    
    
    
  </div>
</div>
  )
}

export default NoteItem