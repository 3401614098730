import { useState } from "react";
import NoteContext from "./NoteContext";
import {toast } from 'react-toastify';


const NoteState=(props)=>{
    let   Authorization=localStorage.getItem('Authorization')
    console.log(Authorization);
    const host='https://my-note-book007-app.herokuapp.com';
    
    
    // const [allNotes, setallNotes] = useState(notes)

    // get all notes
    const [allNotes, setNotes] = useState([])
const getAllNotes=async()=>{
    console.log('auth',Authorization);

   let response=await fetch(`${host}/notes/show-user-data`,{
        method:'GET',
        headers:{
            'Authorization':Authorization,
            'Content-Type':"application/json",
            'Access-Control-Allow-Origin':"http://localhost:3000/",
            'Access-Control-Allow-Credentials':true
            
        }
    })
    console.log("melroy",response);

    let json= await response.json();
    console.log("melroy",json);

    setNotes(json)
    console.log("melroy",allNotes);


}



    // add notes 

    const addNote=async(title, description, tags)=>{
        let originalNotes=[...allNotes];
        let notesArray=[...allNotes,{title,description,tags}];
        setNotes(notesArray);
        toast.success('Note Added!!')
        // eslint-disable-next-line

        try {
            let response=await fetch(`${host}/notes/add-notes`,{
                method:'POST',
                headers:{
                    'Authorization':Authorization,
                    'Content-Type':"application/json",
                    'Access-Control-Allow-Origin':"http://localhost:3000/",
                    'Access-Control-Allow-Credentials':true
                    
                    
                },
                body:JSON.stringify({title,description,tags})
            })
            
        } catch (error) {
         setNotes(originalNotes);
         toast.error('Internal error while saving Note')
        }
         
       
}


const updateNote=()=>{
    // write here the function
}

const deleteNote=async(id)=>{
    toast.success("Item has been deleted!!")
    let originalNotes=allNotes;
        console.log('deleteNotes')
        let resultNote=allNotes.find((note)=>{
            return note._id===id;
        })

        let finalNotes=allNotes.filter((note)=>{
           return note!==resultNote;
        })
        console.log(finalNotes)

        setNotes(finalNotes);


        // try {
        //     await fetch(`${host}/notes/delete-notes/${id}`,{
        //         method:'DELETE',
        //         headers:{
        //             'Authorization':Authorization,
        //              'Content-Type':"application/json",
        //              'Access-Control-Allow-Origin':"http://localhost:3000/",
        //              'Access-Control-Allow-Credentials':true
                    
                    
                    
        //         },
                
        //     })
            
        // } catch (error) {
        //     toast.error("coudn't delete because of internal problem");
        //     setNotes(originalNotes);
            
        // }
         
    
}
    
    


    return(
       < NoteContext.Provider value={{addNote,updateNote,deleteNote,getAllNotes,allNotes}}>
       {props.children}
       </NoteContext.Provider>
    );
}

export default NoteState;