import {React,useEffect} from 'react'
import AddNotes from './AddNotes';
import Notes from './Notes';
import { useNavigate } from 'react-router-dom';
import Alert from './Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
  let navigate=useNavigate();
  useEffect(() => {
    if(!localStorage.getItem('Authorization')){
      return navigate('/login');
    }
  }, [])
  
  
  return (
    <>
    <ToastContainer />
    <Alert/>
    <div className='row ' style={{justifyContent:"center",marginTop:"2rem"}}><AddNotes/></div>
    <div  style={{justifyContent:"center",}}><Notes/></div>
    
    </>
  )
}

export default Home;