import React from 'react'
import { Link,useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import '../navbar.css';


const Navbar = () => {
    let location = useLocation();
    let navigate=useNavigate();

    let handleLogout=()=>{
      localStorage.removeItem('Authorization');
      navigate('/login')
    }
   
  return (
      <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
    <Link className="navbar-brand"  to="/">My Note Book</Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
      {localStorage.getItem('Authorization')?<li className="nav-item">
      <Link className={`nav-link ${location.pathname==='/'?"active":""}`}   to="/">Home</Link>
    </li>:''}
        
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname==='/about'?"active":""}`}  to="/about">About</Link>
        </li>
        
        
      </ul>
      
    </div>
    { localStorage.getItem('Authorization')? 
    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
    <button className="btn btn-dark me-md-2" onClick={handleLogout}>logout</button>
    </div>
    :
    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Link className="btn btn-dark me-md-2" to="/login" >login</Link>
              <Link className="btn btn-dark"  to="/signup">sign-up</Link>
    </div>
      }
  </div>
</nav>
      </>
    
  )
}

export default Navbar