import { React } from 'react'


const About = () => {
   
    
  return (
   
    <div><h1>this is about page  </h1></div>
    
    
  )
}

export default About