import {React,useState,useContext}from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import AlertContext from '../context/AlertContext';


const Login = ( ) => {
  let alertState=useContext(AlertContext);
  const{login}=alertState;
    const [loginValues, setloginValues] = useState({email:"",password:""})
    const {email,password}=loginValues;
    let navigate=useNavigate();

    const onchange=(e)=>{
      console.log({...loginValues,[e.target.name]:e.target.value});
          setloginValues({...loginValues,[e.target.name]:e.target.value});
          }
          
    const handleLogin=async(e)=>{
      e.preventDefault();
        let loginUser=await fetch('https://my-note-book007-app.herokuapp.com/auth/login',{
            method:'POST',
            headers:{
                
                'Content-Type':"application/json",
                // 'Access-Control-Allow-Origin':"https://notebook.melroypinto.com",
                //     'Access-Control-Allow-Credentials':true
                
            },
            body:JSON.stringify({email,password})
          
        })

        const json= await loginUser.json();
       console.log("inside login",json)
       if(json.success){
        localStorage.setItem('Authorization',json.authToken);
          navigate('/');
          login("success","you are logged in!");
       
        
       }
       else {
         alert('unsuccessful');
         navigate('/login');
       }

    }


  return (
    <>
    
    <div className='container'>
    <h2 className="mt-2">Login here</h2>
    <form className="mt-5" onSubmit={handleLogin}>
  <div className="mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
    <input type="email" className="form-control" id="exampleInputEmail1" name="email" onChange={onchange} aria-describedby="emailHelp"/>
    
  </div>
  <div className="mb-3">
    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
    <input type="password" className="form-control" onChange={onchange} name="password" id="exampleInputPassword1"/>
  </div>
  
  <button type="submit" className="btn btn-primary">Submit</button>
</form>
    </div>
    
</>
  )
}

export default Login